import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { cancelSubscription } from 'redux/stipeSubscription/stripe.action';
import { stripeSelector } from 'redux/stipeSubscription/stripe.selector';
import { getUserInfo } from 'redux/user/user.action';
import { userSelector } from 'redux/user/user.selector';

import { subscriptionStatus } from 'types/subscriptionStatus';

import formatDate from 'utils/dateHelper';
import getRemainingDays from 'utils/getRemaindingDays';

import { UserSubscription } from '../types/roles.enum';
import { CircularLogoLoader } from './CircularLogoLoader';

export const SubscriptionHandler: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const remainingDays = userInfo?.subscriptionEndDate
    ? getRemainingDays(userInfo.subscriptionEndDate)
    : undefined;
  const { isLoading } = useAppSelector(stripeSelector);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleCancelClick = () => {
    setOpenDialog(true);
  };

  const handleConfirmCancel = async () => {
    setOpenDialog(false);

    await dispatch(cancelSubscription());
    await dispatch(getUserInfo());
    setShowSuccessMessage(true);
    window.location.reload();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const SubscriptionConditions =
    userInfo?.subscriptionStatus &&
    userInfo.subscriptionEndDate &&
    remainingDays;

  const remindingDaysCondition =
    userInfo?.subscriptionStatus === subscriptionStatus.CANCELLED &&
    remainingDays !== undefined &&
    remainingDays <= 10;

  return (
    <>
      <Box
        sx={{
          margin: isLargeScreen ? '1.5rem' : '0.25rem',
          marginTop: '0.75rem',
          justifyContent: 'center'
        }}>
        <Grid container gap={2} sx={{ minHeight: '20rem' }}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              fontSize={'1rem'}
              fontFamily={'"Nunito"'}
              fontWeight={600}
              lineHeight={'1.75rem'}
              sx={{
                color: Colors.secondary.dark
              }}>
              Review your subscription details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography
                variant="h1"
                fontSize={'1rem'}
                fontFamily={'"Nunito"'}
                fontWeight={600}
                lineHeight={'1.75rem'}
                sx={{
                  color: Colors.secondary.dark
                }}>
                Status:
              </Typography>
              <Box
                sx={{
                  background: Colors.lightGrey,
                  width: '60%',
                  border: `1px solid ${Colors.neutralGrey}`,
                  borderRadius: '0.25rem'
                }}>
                <Typography
                  variant="h1"
                  fontSize={'0.75rem'}
                  fontFamily={'"Nunito"'}
                  fontWeight={600}
                  lineHeight={'1.75rem'}
                  sx={{
                    padding: '0.75rem',
                    color: Colors.secondary.dark
                  }}>
                  {`${userInfo?.plan}`}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              {SubscriptionConditions ? (
                <>
                  <Typography
                    variant="h1"
                    fontSize={'1rem'}
                    fontFamily={'"Nunito"'}
                    fontWeight={600}
                    lineHeight={'1.75rem'}
                    sx={{
                      color: Colors.secondary.dark
                    }}>
                    {userInfo.subscriptionStatus ===
                    subscriptionStatus.CANCELLED
                      ? 'Subscription ends on: '
                      : 'Subscription renews on: '}
                  </Typography>
                  <Box
                    sx={{
                      background: Colors.lightGrey,
                      width: '60%',
                      border: `1px solid ${Colors.neutralGrey}`,
                      borderRadius: '0.25rem'
                    }}>
                    <Typography
                      variant="h1"
                      fontSize={'0.75rem'}
                      fontFamily={'"Nunito"'}
                      fontWeight={600}
                      lineHeight={'1.75rem'}
                      sx={{
                        padding: '0.75rem',
                        color: Colors.secondary.dark
                      }}>
                      {remindingDaysCondition ? (
                        <span
                          style={{
                            color: 'red'
                          }}>{`${remainingDays} days remaining`}</span>
                      ) : (
                        <span>
                          {formatDate(userInfo.subscriptionEndDate || '')}
                        </span>
                      )}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Typography
                  variant="h1"
                  fontSize={'1rem'}
                  fontFamily={'"Nunito"'}
                  fontWeight={600}
                  lineHeight={'1.75rem'}
                  sx={{
                    color: Colors.secondary.dark
                  }}>
                  No active subscription available.
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              borderTop: `0.5px solid ${Colors.neutralGrey}`,
              paddingTop: '0.5rem',
              marginBottom: '1rem'
            }}
            xs={7.5}>
            <Button
              variant="text"
              sx={{
                textTransform: 'none',
                fontFamily: '"Nunito"',
                fontSize: '0.875rem',
                lineHeight: '1.5rem',
                letterSpacing: '0.1px',
                fontWeight: '500'
              }}
              color="secondary"
              disabled={userInfo?.subscription === UserSubscription.FREEMIUM}
              onClick={handleCancelClick}>
              {isLoading ? <CircularLogoLoader /> : 'Cancel Subscription'}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Confirm Cancellation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel your subscription to iAffirm?
            Consider staying to continue enjoying our services.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>No, Stay</Button>
          <Button onClick={handleConfirmCancel} autoFocus>
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSuccessMessage}
        autoHideDuration={3000}
        onClose={() => setShowSuccessMessage(false)}>
        <Alert
          onClose={() => setShowSuccessMessage(false)}
          severity="error"
          sx={{ width: '100%' }}>
          Your subscription has been canceled
        </Alert>
      </Snackbar>
    </>
  );
};
