import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Container, Grid } from '@mui/material';

import AffirmationHeader from 'components/AffirmationHeader';
import { CircularLogoLoader } from 'components/CircularLogoLoader';
import PDFViewerComponent from 'components/PDFrender/PDFViewer';

import { getAdminAudioProfiles } from 'redux/audio-profiles/audio-profiles.action';
import { generateMeditation } from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';
import { getUserCredits } from 'redux/subscription/subscription.action';

import { userSelector } from '../../redux/user/user.selector';
import { analyticsEvents, logAnalyticsEvent } from '../../utils/analytics';
import { AudioProfileMeditation } from './AudioProfile_Meditation';

const MeditationView = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const userMeditation =
    useAppSelector(medidationSelector)?.userMeditation.userMeditation;
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const title =
    userMeditation && userMeditation.meditationTitle
      ? userMeditation?.meditationTitle
      : 'Meditation to start a successful day';

  const [hasFetchedMeditation, setHasFetchedMeditation] = useState(false);
  const [hasFetchedUserCredits, setHasFetchedUserCredits] = useState(false);

  useEffect(() => {
    logAnalyticsEvent(analyticsEvents.meditation.view_meditation_step);
  }, []);

  useEffect(() => {
    if (id && !hasFetchedMeditation) {
      setHasFetchedMeditation(true);
      dispatch(generateMeditation({ meditationId: id }));
    }
  }, [id, dispatch, hasFetchedMeditation]);

  useEffect(() => {
    if (userMeditation && !hasFetchedUserCredits) {
      setHasFetchedUserCredits(true);
      dispatch(getUserCredits());
    }

    dispatch(getAdminAudioProfiles(userInfo?.audioProvider));
  }, [dispatch, userMeditation, hasFetchedUserCredits, userInfo]);

  if (!userMeditation)
    return (
      <Box
        sx={{
          height: 'calc(100vh - 5.5625rem)',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularLogoLoader />
      </Box>
    );

  return (
    <Box
      sx={{
        height: '100%',
        pb: '2rem'
      }}>
      {userMeditation && (
        <AffirmationHeader
          title={title}
          createdOn={dayjs().toDate()}
          pdfUrl={userMeditation.meditationURL}
          developmentArea={'Meditation'}
        />
      )}
      {userMeditation && (
        <Container>
          <Grid container flexWrap="wrap-reverse" mt="1.375rem" spacing={2}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  borderRadius: '0.25rem'
                }}>
                {userMeditation.meditationURL && (
                  <PDFViewerComponent pdfURL={userMeditation.meditationURL} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <AudioProfileMeditation />
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};
export default MeditationView;
