import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, Grid, Snackbar } from '@mui/material';

import DefaultLibrary from 'components/DefaultLibraryAffirmations';

import { Colors } from 'design/theme';

import { affirmationSelector } from 'redux/affirmation/affirmation.selector';
import { setIsSpeechProcessing } from 'redux/affirmation/afirmation.slice';
import { getAdminAudioProfiles } from 'redux/audio-profiles/audio-profiles.action';

import RoutesEnum from 'types/routes.enum';

import { userSelector } from '../../../redux/user/user.selector';

export const AudioProfileAffirmation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isSpeechProcessing } = useAppSelector(affirmationSelector);
  const [processingAudio, setProcessingAudio] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'Library' | 'Custom'>('Library');
  const userInfo = useAppSelector(userSelector).ui.userInfo;

  const handleHomeClick = () => {
    setActiveTab('Library');
  };

  useEffect(() => {
    isSpeechProcessing ? setProcessingAudio(true) : setProcessingAudio(false);
  }, [isSpeechProcessing]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setProcessingAudio(false);
    dispatch(setIsSpeechProcessing(false));
  };

  useEffect(() => {
    dispatch(getAdminAudioProfiles(userInfo?.audioProvider));
  }, [userInfo]);

  return (
    <Box sx={{ padding: '0 1.25rem' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={processingAudio}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Your audio is currently being processed. Once ready, we will send you
          an email notification. Feel free to go to home if you wish.
        </Alert>
      </Snackbar>

      <Box
        sx={{
          width: '100%',
          backgroundColor: Colors.white,
          borderRadius: '0.25rem',
          marginBottom: '1.5rem'
        }}>
        <Grid
          container
          spacing={1}
          sx={{
            padding: '0',
            borderBottom: `1px solid ${Colors.grey}`,
            margin: '0',
            marginBottom: '1rem'
          }}>
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === 'Library'
                    ? `2px solid ${Colors.black}`
                    : undefined,
                borderRadius: '0',
                color: activeTab === 'Library' ? Colors.black : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={handleHomeClick}>
              Library
            </Button>
          </Grid>
          {/* 
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === 'Custom'
                    ? `2px solid ${Colors.black}`
                    : undefined,
                borderRadius: '0',
                color: activeTab === 'Custom' ? Colors.black : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={handleCreatedByMeClick}>
              Custom
            </Button>
          </Grid>
          */}
        </Grid>
        <Box sx={{ padding: '2rem 2rem' }}>
          {activeTab === 'Library' && <DefaultLibrary />}
          {/*activeTab === 'Custom' && <CustomProfileAffirmation />*/}
        </Box>
      </Box>
      {/*
      <CustomAudioPlayer
        title={'Play your affirmation'}
        developmentArea={createdAffirmation?.developmentArea}
        url={isSpeechProcessing ? undefined : speech}
        isLoading={speechLoading}
        createdOn={dayjs().toDate()}
        visualization={false}
      />  
      */}

      <Grid
        item
        xs={12}
        sx={{
          margin: '1.5rem',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate(`${RoutesEnum.USER_HOME}?defaultTab=createdByMe`);
          }}
          disabled={isSpeechProcessing ? false : true}
          sx={{ width: '60%', backgroundColor: Colors.bgGreen }}>
          Home
        </Button>
      </Grid>
    </Box>
  );
};
