import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { SpeechParameters } from 'redux/affirmation/types/affirmation.entity';
import { getUserAudioProfiles } from 'redux/audio-profiles/audio-profiles.action';
import { audioProfilesSelector } from 'redux/audio-profiles/audio-profiles.selector';
import { generateSpeechMeditation } from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';

import { getIntros } from '../redux/intros/intros.action';
import { introsSelector } from '../redux/intros/intros.selector';
import { userSelector } from '../redux/user/user.selector';
import { VoicesEleven } from '../types/voices.enum';
import { analyticsEvents, logAnalyticsEvent } from '../utils/analytics';
import MinimalAudioUrl from './AudioPlayer/MinimalAudioUrl';
import { PromotionModal } from './PromotionModal';

const DefaultLibraryMeditation = () => {
  const dispatch = useAppDispatch();
  const { userAudioProfiles, adminAudioProfiles } = useAppSelector(
    audioProfilesSelector
  );
  const { intros } = useAppSelector(introsSelector);
  const { speech } = useAppSelector(medidationSelector);
  const { userMeditation } = useAppSelector(medidationSelector)?.userMeditation;
  const { id } = useParams();
  const [selectedProfile, setSelectedProfile] = useState<string>('');
  const { audioDuration } = useAppSelector(audioProfilesSelector);

  const [isButtonPressed, setIsButtonPressed] = useState<boolean>(false);
  const [notification, setNotification] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [openModalPromotion, setOpenModalPromotion] = useState<boolean>(false);
  const [confirmCredits, setConfirmCredits] = useState<boolean>(false);

  const user = useAppSelector(userSelector).ui.userInfo;
  const hasCredits = !!(user?.credits && user?.credits > 0);

  const togglePromotionModal = () => {
    setConfirmCredits(false);
    setOpenModalPromotion((prev) => !prev);
  };
  const [selectedIntro, setSelectedIntro] = useState<string>('');
  const [introId, setIntroId] = useState<string | null>(null);
  const [introAudioUrl, setIntroAudioUrl] = useState<string | null>(null);
  const [profileVoice, setProfileVoice] = useState<VoicesEleven | null>(null);

  const handleProfileChange = async (event: SelectChangeEvent<string>) => {
    const profileName = event.target.value;
    setSelectedProfile(profileName);

    const allProfiles = userAudioProfiles.concat(
      adminAudioProfiles ? adminAudioProfiles : []
    );
    const selectedAudioProfile = allProfiles.find((profile) =>
      profile.profileName?.includes(profileName)
    );

    setProfileVoice(selectedAudioProfile?.voice || null);
    setAudioUrl(selectedAudioProfile?.audioURL || null);
  };

  const isAudioGenerated = !!speech;

  const handleGenerateAudio = () => {
    if (!hasCredits) return togglePromotionModal();

    logAnalyticsEvent(analyticsEvents.meditation.generate_meditation_audio);

    const selectedAudioProfile = adminAudioProfiles?.find(
      (profile) => profile.profileName === selectedProfile
    );

    if (id && userMeditation && selectedAudioProfile) {
      setIsButtonPressed(true);
      const speechParameters: SpeechParameters = {
        meditationId: userMeditation.meditationId,
        voice: selectedAudioProfile.voice,
        voiceSpeed: selectedAudioProfile.voiceSpeed,
        background: selectedAudioProfile.background,
        backgroundVolume: selectedAudioProfile.backgroundVolume,
        duration: audioDuration,
        introId: introId || undefined
      };
      dispatch(generateSpeechMeditation(speechParameters));
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification(false);
  };

  const handleIntroChange = async (event: SelectChangeEvent<string>) => {
    const introName = event.target.value;

    if (introName === '') {
      setSelectedIntro('');
      setIntroAudioUrl(null);
      setIntroId(null);
      return;
    }
    setSelectedIntro(introName);

    const selectedIntro = intros.find((intro) =>
      intro.title?.includes(introName)
    );

    const selectedVoiceProfile =
      selectedIntro?.introAudios &&
      selectedIntro?.introAudios?.find((audio) => audio.voice === profileVoice);

    setIntroAudioUrl(selectedVoiceProfile?.audioUrl || null);

    setIntroId(selectedIntro?.id || null);
  };

  useEffect(() => {
    if (intros.length > 0) {
      const firstIntro = intros[0];
      setSelectedIntro(firstIntro.title);
      setIntroId(firstIntro.id || null);

      const selectedVoiceProfile = firstIntro?.introAudios?.find(
        (audio) => audio.voice === profileVoice
      );

      setIntroAudioUrl(selectedVoiceProfile?.audioUrl || null);
    }
  }, [intros, profileVoice]);

  useEffect(() => {
    dispatch(getUserAudioProfiles());
    dispatch(getIntros());
  }, []);

  useEffect(() => {
    setSelectedProfile('');
  }, [user]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem'
      }}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={notification}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Your audio profile has been successfully deleted.
        </Alert>
      </Snackbar>

      <FormControl fullWidth>
        <InputLabel id="audioProfile-label" sx={{ color: Colors.neutralGrey }}>
          Audio Profile
        </InputLabel>
        <Select
          labelId="audioProfile-label"
          id="audioProfile-select"
          value={selectedProfile}
          onChange={handleProfileChange}
          displayEmpty
          renderValue={() => selectedProfile || 'Audio Profile'}
          label="Audio Profile"
          sx={{
            color: Colors.neutralGrey
          }}>
          <MenuItem value="">
            <em>Audio Profile</em>
          </MenuItem>
          {adminAudioProfiles?.map((profile, index) => (
            <MenuItem
              key={index}
              value={profile.profileName}
              sx={{
                backgroundColor:
                  selectedProfile === profile.profileName
                    ? Colors.secondary.light
                    : 'inherit',
                '&:hover': {
                  backgroundColor: Colors.primary
                },
                ...(selectedProfile === profile.profileName && {
                  color: Colors.secondary.main
                })
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}>
                {profile.profileName}
                {adminAudioProfiles?.some(
                  (p) => p.profileName === profile.profileName
                ) && (
                  <StarIcon
                    sx={{ fontSize: 20, ml: 2, color: Colors.secondary.main }}
                  />
                )}
                {selectedProfile === profile.profileName && (
                  <CheckIcon
                    sx={{ fontSize: 20, ml: 2, color: Colors.secondary.main }}
                  />
                )}
              </Box>
            </MenuItem>
          ))}
        </Select>
        <MinimalAudioUrl url={audioUrl ? audioUrl : ''} />
      </FormControl>
      {intros.length > 0 && (
        <FormControl fullWidth>
          <InputLabel
            id="audioProfile-label"
            sx={{ color: Colors.neutralGrey }}>
            Intro
          </InputLabel>
          <Select
            labelId="intro-label"
            id="intro-select"
            value={selectedIntro}
            onChange={handleIntroChange}
            displayEmpty
            renderValue={() => selectedIntro || 'Intro'}
            label="Intro"
            sx={{
              color: Colors.neutralGrey
            }}>
            {intros.map((intro, index) => (
              <MenuItem
                key={index}
                value={intro.title}
                sx={{
                  backgroundColor:
                    selectedIntro === intro.title
                      ? Colors.secondary.light
                      : 'inherit',
                  '&:hover': {
                    backgroundColor: Colors.primary
                  },
                  ...(selectedIntro === intro.title && {
                    color: Colors.secondary.main
                  })
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}>
                  {intro.title}
                  {selectedIntro === intro.title && (
                    <CheckIcon
                      sx={{ fontSize: 20, ml: 2, color: Colors.secondary.main }}
                    />
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
          <MinimalAudioUrl
            url={introAudioUrl && introId && profileVoice ? introAudioUrl : ''}
          />
        </FormControl>
      )}

      <Box
        sx={{
          marginTop: '1rem',
          borderTop: `1px solid ${Colors.lightGrey}`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.5rem'
        }}>
        <Button
          color="secondary"
          fullWidth
          variant="contained"
          onClick={handleGenerateAudio}
          disabled={
            !userMeditation ||
            isAudioGenerated ||
            isButtonPressed ||
            !selectedProfile
          }
          sx={{ height: '3rem', fontSize: '1rem' }}>
          {isAudioGenerated ? 'Generated' : 'Generate audio'}
        </Button>
      </Box>

      <PromotionModal
        open={openModalPromotion}
        handleClose={togglePromotionModal}
        confirmCredits={confirmCredits}
        setConfirmCredits={setConfirmCredits}
      />
    </Box>
  );
};

export default DefaultLibraryMeditation;
