import { AreaSelected } from 'modules/new-affirmation/types/initialState';

import { Background } from 'types/background.enum';
import { AudioDurations } from 'types/durations.enum';
import { VoicesEleven } from 'types/voices.enum';

export interface MeditationState {
  createdMeditation: CreatedMeditation | null;
  userMeditation: CreateMeditationPDF;
  newMeditation: newMeditation;
  meditationOutcomes?: MeditationOutcome[];
  loading: boolean;
  speech?: string;
  speechLoading: boolean;
  processingHome: boolean;
  isSpeechProcessing: boolean;
  qualityByOutcome?: Quality[];
  goalsByOutcome?: Goals[];
  attributeByGoals?: Attribute[];
}

export type MeditationOutcome = {
  id?: number;
  outcome: string;
  recommendation?: boolean;
  qualities?: Quality[];
};

export type Quality = {
  id?: number;
  quality: string;
  outcome?: string;
  goals?: Goals[];
};

export type Goals = {
  id?: number;
  goal: string;
  quality?: Quality;
  attribute?: Attribute;
};

export type Attribute = {
  id?: number;
  attribute: string;
  type?: string;
  goal?: Goals;
};

export interface newMeditation {
  actualStep: number;
  lastStep: number;
  home: boolean;
  customFlow: boolean;
  selectedOutcome?: MeditationOutcome;
  selectedQuality?: Quality;
  selectedGoal?: Goals;
  selectedAttribute?: Attribute;
  areaSelected?: AreaSelected;
  customMeditation?: customMeditationsFlow;
}

export interface customMeditationsFlow {
  outcome: string;
  quality: string;
  goal: string;
  attribute: string;
}

export const customOutcomes: CustomOutcomeMapMeditation = {
  Interpersonal: {
    id: -1,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  },
  Personal: {
    id: -2,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  },
  Professional: {
    id: -3,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  }
};

export interface CustomOutcomeMapMeditation {
  [key: string]: {
    id: number;
    outcome: string;
    recommendation: boolean;
    qualities: Quality[];
  };
}

export interface CreateMeditation {
  outcome?: MeditationOutcome;
  quality?: Quality;
  goal?: Goals;
  attribute?: Attribute;
}

export interface CreatedMeditation {
  id: string;
  outcome?: string;
  quality?: string;
  goal?: string;
  attribue?: string;
}

export interface GetMeditation {
  meditationId: string;
}

export interface UserMeditation {
  meditationId: string;
  meditationTitle: string;
  meditationURL: string;
}

export interface NewMeditationFormik {
  outcome: string;
  quality: string;
  goal: string;
  attribute: string;
}

export interface GetPDFMeditation {
  meditationTitle: string;
  messageMeditation: string;
  meditationId: string;
}

export interface EditMeditation {
  contextTitle: string;
  message: string;
}

export interface EditMeditationResponse {
  meditationId: string;
  meditationTitle: string;
  message: string;
}

export interface CreateMeditationPDF {
  editMeditation?: EditMeditationResponse;
  userMeditation?: UserMeditation;
}

export interface SpeechMeditationParams {
  meditationId: string;
  voiceSpeed: string;
  voice: VoicesEleven;
  background: Background;
  backgroundVolume: number;
  duration: AudioDurations;
}

export interface UpdateMeditation {
  otherQuality: string;
}
