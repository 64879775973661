import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect } from 'react';

import { Box, Container, Typography } from '@mui/material';

import { ModalUserProfile } from 'components/ModalUserProfile';

import { Colors } from 'design/theme';

import { getAffiliationStatus } from 'redux/affiliation/affiliations.action';
import { userHome } from 'redux/user/user.action';
import { setUserProfileModal } from 'redux/user/user.slice';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { userUiSelector } from '../../../redux/user/user.selector';
import { AffiliatesModal } from '../../affiliates-program/AffiliatesModal';
import { CatalogHome } from './CatalogHome';
import UserHome from './UserHome';

export const UserHub: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(userUiSelector);
  const isLargeScreen = useIsLargeScreen();

  useEffect(() => {
    dispatch(setUserProfileModal(false));
    dispatch(getAffiliationStatus());
    dispatch(userHome());
  }, []);

  return (
    <Container
      sx={{
        margin: 'auto',
        marginTop: '3rem',
        justifyContent: 'center',
        pb: '5rem'
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '22rem',
          backgroundColor: Colors.greyWhite,
          zIndex: 0
        }}
      />
      <Box
        sx={{
          zIndex: 200,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: isLargeScreen ? 'left' : 'center',
          flexDirection: 'column',
          marginY: '2rem',
          marginX: isLargeScreen ? '2rem' : 0
        }}>
        <Typography
          data-testid="myHubHeader"
          sx={{
            fontFamily: 'Nunito',
            fontSize: '2rem',
            fontWeight: 600,
            color: Colors.black,
            zIndex: 200
          }}>
          Hi {userInfo?.firstName ?? 'there'} 👋
        </Typography>
        <Typography
          data-testid="myHubHeader"
          sx={{
            fontFamily: 'Nunito',
            fontSize: '1.75rem',
            fontWeight: 600,
            color: Colors.black,
            zIndex: 200,
            ...(!isLargeScreen && { textAlign: 'center', alignSelf: 'center' })
          }}>
          How are you going to manifest your best life?
        </Typography>
      </Box>

      <Container
        sx={{
          marginTop: isLargeScreen ? '0.75rem' : '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2.9rem',
          zIndex: 200
        }}>
        <CatalogHome />
        <UserHome />
      </Container>

      <Box sx={{ zIndex: 200 }}>
        <ModalUserProfile />
        <AffiliatesModal />
      </Box>
    </Container>
  );
};

export default UserHub;
