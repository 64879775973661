import { Box, Button } from '@mui/material';

import { Colors } from 'design/theme';

import useTabsContext from '../hooks/useTabsContext';
import TabsContext from './TabsContext';

type StepsMenuProps = {
  backAction?: () => void;
  nextAction?: () => void;
};

const StepsMenu = ({ backAction, nextAction }: StepsMenuProps) => {
  const { goNextTab, goBackTab } = useTabsContext(TabsContext);

  return (
    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
      {backAction && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={backAction || goBackTab}>
          Back
        </Button>
      )}
      <Button
        variant="contained"
        sx={{ backgroundColor: Colors.bgGreen }}
        color="secondary"
        onClick={nextAction || goNextTab}>
        Next
      </Button>
    </Box>
  );
};

export default StepsMenu;
