import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Typography
} from '@mui/material';

import CustomAudioPlayer from 'components/AudioPlayer/AudioPlayer';
import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { areaEmojis } from 'modules/new-affirmation/components/Steps/2_improvementArea';

import {
  deleteAffirmation,
  getMorningAffirmations,
  getSpeech
} from 'redux/affirmation/affirmation.action';
import { affirmationSelector } from 'redux/affirmation/affirmation.selector';
import {
  MeditationHome,
  SpeechParameters,
  VisualizationHome
} from 'redux/affirmation/types/affirmation.entity';
import {
  deleteMeditation,
  generateSpeechMeditation
} from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';
import { getUserInfo, userHome } from 'redux/user/user.action';
import { userUiSelector } from 'redux/user/user.selector';
import {
  deleteVisualization,
  speechVisualization
} from 'redux/visualization/visualization.action';
import { visualizationSelector } from 'redux/visualization/visualization.selector';

import { Background } from 'types/background.enum';
import { VoiceSpeed } from 'types/speed.enum';
import { VoicesEleven, VoicesOpenAi } from 'types/voices.enum';

import { ReactComponent as MagicStick } from '../../../assets/images/Stick.svg';
import { AudioProvider } from '../../../redux/user/types/userState.interface';

type AffirmationOrVisualization = {
  id: string;
  title: string;
  developmentArea: string;
  audioUrl: string;
  isLoading: boolean;
  createdAt: Date;
  audioProcessed?: boolean;
  pdfUrl: string;
  visualization: boolean;
  meditation: boolean;
  audioConditioned?: boolean;
  area: string;
};

const UserHome = () => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();
  const [isRendering, setIsRendering] = useState(true);
  const [generatingID, setGeneratingID] = useState<string | null>(null);
  const [offset, setOffset] = useState<number>(0);

  const { isSpeechProcessing } = useAppSelector(affirmationSelector);
  const { speechLoading: affirmationLoading } =
    useAppSelector(affirmationSelector);
  const { speechLoading: visualizationLoading } = useAppSelector(
    visualizationSelector
  );
  const { speechLoading: meditationLoading } =
    useAppSelector(medidationSelector);
  const { homeData, loading, userInfo } = useAppSelector(userUiSelector);
  const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  const [affirmationsAndVisualizations, setAffirmationsAndVisualizations] =
    useState<AffirmationOrVisualization[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const handleAudioToggle = (audioUrl: string) => {
    setCurrentlyPlaying((currentUrl) =>
      currentUrl === audioUrl ? null : audioUrl
    );
  };
  const { morningAffirmation } = useAppSelector(affirmationSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageItems, setCurrentPageItems] = useState<
    AffirmationOrVisualization[]
  >([]);

  const handleGenerateLateAudio = (
    id: string,
    isVisualization: boolean,
    isMeditation: boolean
  ) => {
    if (id && userInfo) {
      const voice =
        userInfo.audioProvider === AudioProvider.ELEVENLABS
          ? VoicesEleven['Adam']
          : VoicesOpenAi.Alloy;

      let speechParameters: SpeechParameters = {};
      setGeneratingID(id);
      if (isVisualization) {
        speechParameters = {
          visualizationID: id,
          voiceSpeed: VoiceSpeed['Moderate'],
          voice,
          background: Background['Northern']
        };
      } else if (isMeditation && !isVisualization) {
        speechParameters = {
          meditationId: id,
          voiceSpeed: VoiceSpeed['Moderate'],
          voice,
          background: Background['NewAge']
        };
      } else {
        speechParameters = {
          affirmationID: id,
          voiceSpeed: VoiceSpeed['Moderate'],
          voice,
          background: Background['NewAge']
        };
      }

      if (isVisualization) {
        dispatch(speechVisualization(speechParameters));
      } else if (isMeditation) {
        dispatch(generateSpeechMeditation(speechParameters));
      } else {
        dispatch(getSpeech(speechParameters));
      }
    }
  };

  useEffect(() => {
    dispatch(getUserInfo());
    if (!morningAffirmation) dispatch(getMorningAffirmations());
  }, [dispatch, morningAffirmation]);

  useEffect(() => {
    if (!homeData) return;

    const totalItems =
      (homeData.affirmations ? homeData.affirmations.length : 0) +
      (homeData.visualizations ? homeData.visualizations.length : 0) +
      (homeData.meditations ? homeData.meditations.length : 0);

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    setTotalPages(totalPages);

    const { affirmations, visualizations, meditations } = homeData;
    const afAndVi: AffirmationOrVisualization[] = [];

    affirmations.forEach((affirmation) => {
      afAndVi.push({
        id: affirmation.affirmationID,
        title: affirmation.title,
        developmentArea: affirmation.developmentArea || 'Featured',
        audioUrl: affirmation.audio,
        isLoading: false,
        createdAt: dayjs(affirmation.createdAt).toDate(),
        pdfUrl: affirmation.pdf,
        audioProcessed: affirmation.audio ? affirmation.audioProcessed : false,
        visualization: false,
        meditation: false,
        audioConditioned: affirmation.audioProcessed,
        area: 'Affirmation'
      });
    });
    if (visualizations) {
      visualizations.forEach((visualization: VisualizationHome) => {
        afAndVi.push({
          id: visualization.visualizationsId,
          title:
            visualization.otherQuality ||
            `I visualize myself in ${visualization.context}`,
          developmentArea: '',
          audioUrl: visualization.audio,
          isLoading: false,
          createdAt: dayjs(visualization.createdAt).toDate(),
          pdfUrl: visualization.pdf,
          audioProcessed: visualization.audio
            ? visualization.audioProcessed
            : false,
          visualization: true,
          meditation: false,
          audioConditioned: visualization.audioProcessed,
          area: 'Visualization'
        });
      });
    }
    if (meditations) {
      meditations.forEach((meditation: MeditationHome) => {
        afAndVi.push({
          id: meditation.meditationsId,
          title: meditation.otherQuality || meditation.quality,
          developmentArea: 'Meditation',
          audioUrl: meditation.audioMeditation,
          isLoading: false,
          createdAt: dayjs(meditation.createdAt).toDate(),
          pdfUrl: meditation.pdf,
          audioProcessed: meditation.audioMeditation
            ? meditation.audioProcessed
            : false,
          visualization: false,
          meditation: true,
          audioConditioned: meditation.audioProcessed,
          area: 'Meditation'
        });
      });
    }

    afAndVi.sort((a, b) => {
      return a.createdAt > b.createdAt ? -1 : 1;
    });

    setAffirmationsAndVisualizations(afAndVi);
    setIsRendering(false);
  }, [homeData]);

  useEffect(() => {
    if (
      !affirmationLoading &&
      !visualizationLoading &&
      !meditationLoading &&
      generatingID
    ) {
      dispatch(userHome());
      setGeneratingID(null);
    }
  }, [
    affirmationLoading,
    visualizationLoading,
    generatingID,
    isSpeechProcessing,
    dispatch,
    offset,
    meditationLoading
  ]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
    const itemsPerPage = 3;
    const newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
  };

  const isPrevDisabled = offset <= 1 || loading;
  const isNextDisabled = currentPage >= totalPages || loading;
  const itemsPerPage = 3;

  const handleDeleteAffirmation = async (area: string, id: string) => {
    if (area === 'Affirmation') dispatch(deleteAffirmation(id));
    if (area === 'Visualization') dispatch(deleteVisualization(id));
    if (area === 'Meditation') dispatch(deleteMeditation(id));

    window.location.reload();
  };

  useEffect(() => {
    const currentItems = affirmationsAndVisualizations.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    setCurrentPageItems(currentItems);
  }, [offset, affirmationsAndVisualizations, homeData]);

  return (
    <Box>
      <>
        <Grid container spacing={2}>
          {loading || isRendering ? (
            <Box
              mt={5}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
              }}>
              <CircularLogoLoader />
            </Box>
          ) : (
            <>
              {morningAffirmation && (
                <Grid
                  item
                  xs={12}
                  key={morningAffirmation.id}
                  marginTop={'0.5rem'}>
                  <Typography
                    fontWeight={500}
                    fontSize={'1.25rem'}
                    lineHeight={'normal'}
                    color={Colors.bgGreen}
                    fontFamily='"Nunito"'
                    mb={1.5}>
                    Your morning affirmation
                  </Typography>
                  <CustomAudioPlayer
                    id={morningAffirmation.id}
                    title={`${
                      areaEmojis[
                        morningAffirmation.developmentArea as keyof typeof areaEmojis
                      ]
                    } ${morningAffirmation.title}`}
                    developmentArea={morningAffirmation.developmentArea}
                    variant="home"
                    url={morningAffirmation.audioURL}
                    createdOn={new Date(morningAffirmation.createdAt)}
                    visualization={false}
                  />
                </Grid>
              )}
              {morningAffirmation && (
                <Grid item xs={12} marginTop={'0.5rem'}>
                  <hr
                    color={Colors.lightGrey}
                    style={{
                      width: '100%'
                    }}
                  />
                </Grid>
              )}
              {currentPageItems.map((affirmationOrVisualization) => (
                <Grid
                  item
                  xs={12}
                  key={affirmationOrVisualization.id}
                  marginTop={'0.5rem'}>
                  <CustomAudioPlayer
                    id={affirmationOrVisualization.id}
                    title={affirmationOrVisualization.title}
                    emoji={
                      !affirmationOrVisualization.visualization
                        ? areaEmojis[
                            affirmationOrVisualization.developmentArea as keyof typeof areaEmojis
                          ]
                        : undefined
                    }
                    developmentArea={`${affirmationOrVisualization.developmentArea}`}
                    variant="home"
                    url={affirmationOrVisualization.audioUrl}
                    onTogglePlay={() =>
                      handleAudioToggle(affirmationOrVisualization.audioUrl)
                    }
                    isPlaying={
                      currentlyPlaying === affirmationOrVisualization.audioUrl
                    }
                    isLoading={
                      (affirmationLoading ||
                        visualizationLoading ||
                        meditationLoading) &&
                      generatingID === affirmationOrVisualization.id
                    }
                    createdOn={affirmationOrVisualization.createdAt}
                    pdfUrl={affirmationOrVisualization.pdfUrl}
                    visualization={affirmationOrVisualization.visualization}
                    onGenerateLateAudio={() =>
                      handleGenerateLateAudio(
                        affirmationOrVisualization.id,
                        affirmationOrVisualization.visualization,
                        affirmationOrVisualization.meditation
                      )
                    }
                    audioProcessed={affirmationOrVisualization.audioConditioned}
                    anyAudioProcessed={isSpeechProcessing}
                    area={affirmationOrVisualization.area}
                    onDelete={() =>
                      handleDeleteAffirmation(
                        affirmationOrVisualization.area,
                        affirmationOrVisualization.id
                      )
                    }
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>

        <Box
          mt={3}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem'
          }}>
          {totalPages > 1 && !isRendering && (
            <Pagination
              defaultPage={1}
              count={totalPages}
              variant="outlined"
              color="primary"
              onChange={handlePaginationChange}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{ fontFamily: 'Nunito', alignItems: 'center' }}
                  disabled={
                    (item.type === 'previous' && isPrevDisabled) ||
                    (item.type === 'next' && isNextDisabled)
                  }
                />
              )}
            />
          )}
        </Box>
      </>
      {totalPages === 0 &&
        affirmationsAndVisualizations.length <= 0 &&
        !isRendering && (
          <Box sx={{ height: '20rem' }}>
            <Box
              sx={{
                margin: 'auto',
                background: 'rgba(53, 101, 105, 0.3)',
                height: '10rem',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '0.25rem',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
              <Typography
                fontFamily='"Nunito"'
                fontSize={'1.25rem'}
                color={Colors.bgGreen}
                sx={{
                  width: '80%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '0.25rem',
                  textAlign: 'center',
                  gap: '1rem'
                }}>
                {isLargeScreen && <MagicStick />}
                Try creating your first affirmation or visualization to continue
              </Typography>
              <Box>{!isLargeScreen && <MagicStick />}</Box>
            </Box>
          </Box>
        )}
    </Box>
  );
};

export default UserHome;
