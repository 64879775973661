import { useFormikContext } from 'formik';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, TextField, Tooltip, Typography } from '@mui/material';

import StepsMenu from 'modules/new-visualization/components/StepsMenu';
import TabsContext from 'modules/new-visualization/components/TabsContext';
import useTabsContext from 'modules/new-visualization/hooks/useTabsContext';

import { NewMeditationFormik } from 'redux/meditations/types/meditation.entity';

import { NewMeditationTabs } from '../NewMeditationForm';

const qualityTooltipText =
  'Add the goal that you want to achieve, such as “I want to increase my motivation';
const GoalStep = () => {
  const { goNextTab, goToTab } = useTabsContext(TabsContext);

  const { handleChange, handleBlur, values, errors, touched, setTouched } =
    useFormikContext<NewMeditationFormik>();

  const handleNextStep = async () => {
    const errorsX = await setTouched({ goal: true });
    const isContextValid = values.goal.trim().length > 0;

    if (errorsX && !('quality' in errorsX) && isContextValid) goNextTab();
  };

  const handleBackStep = async () => {
    goToTab(NewMeditationTabs.ATTRIBUTE);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <Typography sx={{ fontSize: 24, marginBottom: 4 }}>
          With the goal of...{' '}
        </Typography>

        <Tooltip title={qualityTooltipText} placement="top-start">
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              userSelect: 'none'
            }}>
            <ErrorOutlineIcon color="secondary" />

            <Typography color="secondary">Show an example</Typography>
          </Box>
        </Tooltip>
      </Box>

      <Box sx={{ width: '100%', marginBottom: 20 }}>
        <TextField
          placeholder="| Describe the goal you are trying to achieve"
          name="goal"
          variant="standard"
          value={values.goal}
          id={values.goal}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.goal && Boolean(errors.goal)}
          helperText={touched.goal && errors.goal}
          fullWidth
          multiline
          color="secondary"
          inputProps={{
            style: { fontSize: 36, lineHeight: 1, marginBottom: 40 },
            maxLength: 150
          }}
        />

        <Typography sx={{ marginTop: 3 }}>
          {150 - values.goal.length} characters remaining (Minimum 30
          characters)
        </Typography>
      </Box>

      <StepsMenu nextAction={handleNextStep} />
    </Box>
  );
};

export default GoalStep;
