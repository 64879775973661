import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useState } from 'react';

import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import {
  addDefaultPaymentMethod,
  stripeSubscription
} from 'redux/stipeSubscription/stripe.action';
import { userSelector } from 'redux/user/user.selector';

import { UserSubscription } from 'types/roles.enum';

import { countries } from 'utils/countries';
import { getCurrencyByCountryName } from 'utils/getLocation';

import { stripeSelector } from '../redux/stipeSubscription/stripe.selector';
import { CircularLogoLoader } from './CircularLogoLoader';

interface FormValues {
  email: string;
  cardName: string;
  country: string;
  zip: string;
}
interface PaymentDetails {
  selectedOption: string;
  tabBack: (option: string) => void;
}
const PaymentForm: React.FC<PaymentDetails> = ({
  selectedOption,
  tabBack
}: PaymentDetails) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const { error } = useAppSelector(stripeSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const currencyOption = getCurrencyByCountryName(userInfo?.country || '');
  const [sucesssSubscription, setSucesssSubscription] =
    useState<boolean>(false);

  const initialValues: FormValues = {
    email: '',
    cardName: '',
    country: '',
    zip: ''
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setIsLoading(true);
    setErrorMessage(null);
    if (!stripe || !elements || !userInfo?.stripeCustomerId) {
      setSubmitting(false);
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setSubmitting(false);
      setIsLoading(false);
      return;
    }

    const paymentMethodRes = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    if (paymentMethodRes.error) {
      setErrorMessage(paymentMethodRes.error.message || 'Something went wrong');
      setSubmitting(false);
      setIsLoading(false);
      return;
    }
    const addPaymentMethodResult = await dispatch(
      addDefaultPaymentMethod({
        paymentMethodId: paymentMethodRes.paymentMethod.id,
        customerId: userInfo.stripeCustomerId
      })
    );

    if (addPaymentMethodResult.meta.requestStatus === 'fulfilled') {
      const subscriptionResult = await dispatch(
        stripeSubscription({ planId: selectedOption, currency: currencyOption })
      );

      if (subscriptionResult.meta.requestStatus === 'fulfilled') {
        setSucesssSubscription(true);
      } else {
        setSucesssSubscription(false);
      }
    }

    setIsLoading(false);
    setSubmitting(false);
  };

  return (
    <>
      <Box
        sx={{
          marginTop:
            userInfo?.subscription === UserSubscription.PREMIUM ||
            sucesssSubscription
              ? '0'
              : '1.5rem'
        }}>
        {sucesssSubscription ? (
          <Box
            sx={{
              minHeight: '16rem',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
            <Typography
              fontFamily={'"Nunito"'}
              fontSize={'1.5rem'}
              fontWeight={600}
              sx={{
                color: Colors.secondary.main,
                marginBottom: '1rem'
              }}>
              {"You're Now a Premium Member!"}
            </Typography>
            <Typography
              fontFamily={'"Nunito"'}
              fontSize={'1rem'}
              fontWeight={400}
              sx={{ color: Colors.secondary.dark }}>
              Thank you for being part of the iAffirm premium community!
            </Typography>
          </Box>
        ) : (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, handleChange, values }) => (
              <Form>
                <Grid container rowGap={1} columnGap={0} mb={'1rem'}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderRadius: '0.25rem'
                    }}>
                    <TextField
                      color="secondary"
                      value={userInfo?.email}
                      onChange={handleChange}
                      name="email"
                      fullWidth
                      id="email"
                      label="Email"
                      placeholder="Email *"
                      autoComplete="email"
                      type="email"
                      sx={{
                        fontFamily: '"Nunito"',
                        fontWeight: '400',
                        color: Colors.brown.main
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: '0.75rem',
                      borderRadius: '0.25rem'
                    }}>
                    <TextField
                      color="secondary"
                      value={values.cardName}
                      onChange={handleChange}
                      name="cardName"
                      fullWidth
                      id="cardName"
                      label="Name on card"
                      placeholder="Name on card *"
                      sx={{
                        fontFamily: '"Nunito"',
                        fontWeight: '400',
                        color: Colors.brown.main
                      }}
                    />
                  </Grid>

                  {/* Add other fields like country and zip code here */}

                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: '0.75rem',
                      padding: '1.15rem',
                      border: `1px solid ${Colors.neutral}`,
                      borderRadius: '0.25rem'
                    }}>
                    <CardElement
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            color: 'black',
                            '::placeholder': {
                              color: 'black'
                            }
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    mb="1rem"
                    mt="0.75rem"
                    sx={{
                      display: 'flex',
                      alignContent: 'flex-start',
                      justifyContent: 'flex-start'
                    }}>
                    <Select
                      labelId="country-selector-label"
                      id="country"
                      name="country"
                      value={userInfo?.country}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{
                        width: '97.5%',
                        fontFamily: '"Nunito"',
                        fontWeight: '400',
                        color: Colors.brown.main
                      }}>
                      {countries.map((country) => (
                        <MenuItem key={country.label} value={country.label}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      alignContent: 'flex-end',
                      justifyContent: 'flex-end',
                      marginTop: '0.75rem',
                      borderRadius: '0.25rem'
                    }}>
                    <TextField
                      color="secondary"
                      value={values.zip}
                      onChange={handleChange}
                      name="zip"
                      id="zipCode"
                      label="ZIP Code"
                      placeholder="ZIP Code"
                      sx={{
                        width: '97.5%',
                        fontFamily: '"Nunito"',
                        fontWeight: '400',
                        color: Colors.brown.main
                      }}
                    />
                  </Grid>
                </Grid>
                {(errorMessage || error) && (
                  <Typography color="error">{errorMessage || error}</Typography>
                )}

                {isLoading ? (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center'
                    }}>
                    <CircularLogoLoader />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    gap={2}
                    justifyContent="flex-end"
                    flexWrap="wrap">
                    <Button
                      color="secondary"
                      onClick={() => {
                        tabBack('choosePlan');
                      }}
                      fullWidth={!isLargeScreen}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      fullWidth={!isLargeScreen}
                      disabled={isSubmitting || !stripe}>
                      Subscribe
                    </Button>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
};

export default PaymentForm;
