import {
  SpeechParameters,
  Visualization
} from 'redux/affirmation/types/affirmation.entity';
import {
  GetVisualization,
  getPDFVisualization
} from 'redux/visualization/types/getVisualization.interface';
import {
  CreateVisualization,
  CreatedVisualization,
  EditMeditation,
  Outcome,
  QualityBrief,
  SituationsBrief,
  UpdateVisualization,
  UserVisualization,
  UserVisualizationCreated
} from 'redux/visualization/types/visualizations';

import { IApiResponse } from 'types/apiResponse.interface';

import { api, baseURL } from './instance';

const visualizationService = {
  create: (data: CreateVisualization) => {
    return api.post<IApiResponse<CreatedVisualization>>(
      `${baseURL}/visualizations`,
      data
    );
  },
  generate: (data: GetVisualization) => {
    return api.post<IApiResponse<EditMeditation>>(
      `${baseURL}/visualizations/generate-visualization`,
      data
    );
  },
  generatePDF: (data: getPDFVisualization) => {
    return api.post<IApiResponse<UserVisualizationCreated>>(
      `${baseURL}/visualizations/generate-pdf-visualization`,
      data
    );
  },
  speech: (speechParameters: SpeechParameters) => {
    return api.post<IApiResponse<UserVisualization>>(
      `${baseURL}/visualizations/speech-visualization`,
      speechParameters
    );
  },
  getOutcomes: () => {
    return api.get<IApiResponse<Outcome[]>>(
      `${baseURL}/visualizations/outcomes`
    );
  },
  getOutcomeQualities: (id: number) => {
    return api.get<IApiResponse<QualityBrief[]>>(
      `${baseURL}/visualizations/outcome-qualities/${id}`
    );
  },
  getEmotionSituations: (id: number) => {
    return api.get<IApiResponse<SituationsBrief[]>>(
      `${baseURL}/visualizations/emotion-situation/${id}`
    );
  },
  deleteVisualization: (id: string) => {
    const response = api.delete<IApiResponse<boolean>>(
      `${baseURL}/visualizations/delete-visualization/${id}`
    );
    return response;
  },
  updateVisualization: (id: string, data: Partial<UpdateVisualization>) => {
    return api.patch<IApiResponse<Visualization>>(
      `${baseURL}/visualizations/${id}`,
      data
    );
  }
};

export default visualizationService;
