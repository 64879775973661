import { useFormikContext } from 'formik';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, TextField, Tooltip, Typography } from '@mui/material';

import StepsMenu from 'modules/new-visualization/components/StepsMenu';
import TabsContext from 'modules/new-visualization/components/TabsContext';
import useTabsContext from 'modules/new-visualization/hooks/useTabsContext';

import { NewMeditationFormik } from 'redux/meditations/types/meditation.entity';

import { NewMeditationTabs } from '../NewMeditationForm';

const qualityTooltipText =
  'Add the quality that you want to work on, such as “I want to work on my inspiration';
const OutcomeStep = () => {
  const { goNextTab, goToTab } = useTabsContext(TabsContext);

  const { handleChange, handleBlur, values, errors, touched, setTouched } =
    useFormikContext<NewMeditationFormik>();

  const handleNextStep = async () => {
    const errorsX = await setTouched({ outcome: true });
    const isContextValid = values.outcome.trim().length > 0;

    if (errorsX && !('outcome' in errorsX) && isContextValid) goNextTab();
  };

  const handleBackStep = async () => {
    goToTab(NewMeditationTabs.QUALITY);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <Typography sx={{ fontSize: 24, marginBottom: 4 }}>
          Write down a outcome
        </Typography>

        <Tooltip title={qualityTooltipText} placement="top-start">
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              userSelect: 'none'
            }}>
            <ErrorOutlineIcon color="secondary" />

            <Typography color="secondary">Show an example</Typography>
          </Box>
        </Tooltip>
      </Box>

      <Box sx={{ width: '100%', marginBottom: 20 }}>
        <TextField
          placeholder="| Type your outcome"
          name="outcome"
          variant="standard"
          value={values.outcome}
          id={values.outcome}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.outcome && Boolean(errors.outcome)}
          helperText={touched.outcome && errors.outcome}
          fullWidth
          multiline
          color="secondary"
          inputProps={{
            style: { fontSize: 36, lineHeight: 1, marginBottom: 40 },
            maxLength: 150
          }}
        />

        <Typography sx={{ marginTop: 3 }}>
          {150 - values.outcome.length} characters remaining (Minimum 30
          characters)
        </Typography>
      </Box>

      <StepsMenu nextAction={handleNextStep} />
    </Box>
  );
};

export default OutcomeStep;
